import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

const FloatContainer = ({ children, width, align, textAlign }) => {
  return (
    <Box
      as="div"
      sx={{
        float: ["none", `${align}`],
        width: ["100%", `${width}`],
        ml: align === "left" ? 0 : [0, 6],
        mr: align === "right" ? 0 : [0, 6],
        textAlign,
        mb: [6, 3],
      }}
    >
      {children}
    </Box>
  )
}

FloatContainer.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  width: PropTypes.string,
}

FloatContainer.defaultProps = {
  align: "left",
  width: "30%",
}

export default FloatContainer
