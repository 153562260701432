import React, { useState } from "react"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Clearfix from "../../../../../components/clearfix"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FloatContainer from "../../../../../components/float-container"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Paper from "../../../../../components/paper"
import Radio from "../../../../../components/radio"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import BriefmarkeMannheim from "../../../../../images/kurse/briefmarke-mannheim.svg"
import BriefmarkeSpeyer from "../../../../../images/kurse/briefmarke-speyer.svg"
import BriefmarkeRheinhausen from "../../../../../images/kurse/briefmarke-rheinhausen.svg"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "tagebuch",
}

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)
  const [stamp, setStamp] = useState(false)

  const answer = getAnswer({
    ...taskProps,
    taskId: "tagebuch-03",
  })

  const stamps = {
    stamp01: BriefmarkeMannheim,
    stamp02: BriefmarkeSpeyer,
    stamp03: BriefmarkeRheinhausen,
  }

  const cities = {
    stamp01: "Mannheim",
    stamp02: "Speyer",
    stamp03: "Rheinhausen",
  }

  const nl2br = (text) => {
    const regex = /(\r\n|\r|\n)/g
    return text.split(regex).map((line, i) => {
      if (line.match(regex)) {
        return <br key={`br-${i}`} />
      }
      return line
    })
  }

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zur Zusammenfassung"
          to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/zusammenfassung"
        />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Brief abschicken" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Dein Brief
          </Heading>
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={5}>
            Fertig! Fehlt nur noch die passende Briefmarke:
          </Heading>
          <Grid columns={[1, 3]} space={3}>
            {Object.entries(stamps).map(([id, value], i) => (
              <Box
                as="label"
                htmlFor={id}
                sx={{
                  display: "block",
                  width: "100%",
                  p: 6,
                  bg: stamp === id ? "black" : "muted",
                  borderRadius: "lg",
                  cursor: "pointer",
                }}
              >
                <Radio
                  key={id}
                  id={id}
                  name="stamp"
                  value={id}
                  checked={stamp === id}
                  onChange={() => {
                    setStamp(id)
                  }}
                >
                  {cities[id]}
                </Radio>
              </Box>
            ))}
          </Grid>
        </Stack>
        <Paper>
          <Clearfix>
            <FloatContainer
              align="right"
              width="auto"
              textAlign={["left", "right"]}
            >
              <Box
                aria-hidden="true"
                borderRadius="lg"
                border={4}
                borderColor="border"
                borderStyle="dashed"
                width={48}
                height={48}
                display="inline-block"
                position="relative"
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    left: -1,
                    top: -1,
                    bottom: -1,
                    right: -1,
                    transform: "rotate(-1deg)",
                  }}
                >
                  {stamp && (
                    <img
                      src={stamps[stamp]}
                      alt={"Briefmarke"}
                      width="201"
                      height="233"
                      sx={{
                        pointerEvents: "none",
                        width: "auto",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </FloatContainer>
            <Paragraph>{answer && nl2br(answer.letter)}</Paragraph>
          </Clearfix>
        </Paper>
        {/* <Paragraph>
          Willst du deinen Brief drucken oder speichern? Lass dir von deinen
          Eltern oder Lehrern helfen:{" "}
          <TextLink
            onClick={(event) => {
              event.preventDefault()
              window.print()
            }}
          >
            Brief drucken
          </TextLink>
        </Paragraph> */}
      </Stack>
    </LayoutCourse>
  )
}

export default Page
